import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
const imgversioncontrol = "../../images/guides/versioncontrol.png"

const VersionControlPage = () => {
	return (

	<Layout activePath='guides/versioncontrol' pageTitle="Version Control (Centralized)" description="Overview of centralized version control"  meta={[{name: `robots`, content: `noindex`}]}>
		<div className="margin-content">
			<h1>Version Control (Centralized)</h1>
			Systems that manage revisions of multiple users on the same set of files (<i>workspace</i>).  This is usually done by having a central storage (<i>repository</i>) that coordinates changes in the workspace.
			<h3>General Flow</h3>
			<i>Terminologies may vary</i>
			<StaticImage layout="fullWidth" src={imgversioncontrol} alt={"Version Control"} />
			<ul>
				<li>Check out - One-time step to obtain a version of a workspace from the repository as your <i>local/working copy</i></li>
				<li>Modify - Make changes to the working copy
					<ul>
						<li><b>Add</b> - Add files and/or folders to the repository </li>
						<li><b>Remove</b> - Delete files and/or folders from the repository</li>
						<li>Edit files in your working copy</li>
					</ul>
				</li>
				<li>Update - Get changes of other users (if any).  This may result to one of the following:
					<ul>
							<li><b>Conflict</b> - Changes were made to a file that you have modified. One of the following actions will be necessary:
								<ul>
									<li><i>Merge</i> - System attempts to resolve conflict automatically by integrating changes made to your current file</li>
									<li><i>Resolve</i> - Inform system that you have manually integrated your changes</li>
								</ul>
							</li>
							<li><b>Updated</b> - Changes were made to files that you did not modify</li>
							<li>Nothing - no changes were made since last update</li>
					</ul>
				</li>
				<li>Diff - Compare the working copy with the repository to check if all changes are correct</li>
				<li>Revert - Remove changes; Restore file to the current version</li>
				<li>Commit - Submit changes to the repository (system normal performs update first)</li>
			</ul>
			<h3>Benefits of a Version Control system</h3>
			<ul>
				<li>Multiple developers can work on the same set of files with less worry</li>
				<li>Backup</li>
				<li>Tracking/Backtracking of changes across several versions</li>
			</ul>
			Popular systems for source code version control are:
			<ul>
				<li>Git</li>
				<li>SVN</li>
				<li>CVS</li>
			</ul>
			<h2>
			<a target='_blank' rel="noreferrer" href='https://betterexplained.com/articles/intro-to-distributed-version-control-illustrated/'>read: Distributed Version Control Systems</a>
			</h2>
			<h2>
			<a target='_blank' rel="noreferrer" href='https://try.github.io/levels/1/challenges/1'>try: Hands-on Git exercises</a>
			</h2>
		</div>

	</Layout>
	)
}

export default VersionControlPage
